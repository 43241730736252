import MixinService from "@/services/MixinService";
import i18n from "@/lang/i18n"

export default {
  data() {
    return {
      offices: [],
      officePlaceholder: i18n.t('users_management.labels.input_field.office.placeholder'),
      positions: [],
      positionPlaceholder: i18n.t('users_management.labels.input_field.position.placeholder'),
      roles: [
        // {id: 1, name: 'System Admin'},
        {id: 2, name: '管理者'},
        {id: 3, name: '役職者'},
        {id: 4, name: '一般従業員'},
      ],
      rolePlaceholder: i18n.t('users_management.labels.input_field.role.placeholder'),
      divisions: [],
      divisionPlaceholder: i18n.t('users_management.labels.input_field.division.placeholder'),
      branches: [],
      branchPlaceholder: i18n.t('users_management.labels.input_field.branch.placeholder'),
      modalOpened: false,
      positionStaffs: [],
    }
  },
  methods: {
    closeDropdown(id) {
      this.$nextTick(() => {
        if (this.$el.querySelector(`#${id}`)) {
          this.$el.querySelector(`#${id}`).classList.remove('is-active')
        }
      })
    },
    toggleDropdown(id) {
      this.$nextTick(() => {
        if (this.$el.querySelector(`#${id}`)) {
          this.$el.querySelector(`#${id}`).classList.toggle('is-active')
          this.$refs['dropdown-keyword'] && this.$refs['dropdown-keyword'].focus()
        }
      })
    },
    closeModal(id) {
      if (this.modalOpened) {
        this.$nextTick(() => {
          if (this.$el.querySelector(`#${id}`)) {
            this.$el.querySelector(`#${id}`).classList.remove('is-active')
          }
        })
        setTimeout(() => {
          this.modalOpened = false
        })
      }
    },
    toggleModal(id) {
      this.$nextTick(() => {
        if (this.$el.querySelector(`#${id}`)) {
          this.$el.querySelector(`#${id}`).classList.toggle('is-active')
        }
      })
    },
    dropDisplay(list, id) {
      let name = ''
      list.map((e) => {
        if (e.id === id) {
          name = e.name
        }
      })
      return name
    },

    // call API list
    async getData(type) {
      await MixinService[type]()
        .then((res) => {
          if (res) {
            if (res.data[type].length) {
              this[type] = res.data[type]
              if(type === 'positions') {
                this.positions.map((e) => {
                  if(e.role && e.role.id === 4) {
                    this.positionStaffs = [...this.positionStaffs, ...[e]]
                  }
                })
              }
            }
          }
        })
        .catch((err) => {
          this.$toast.error(err.data.message)
        })
    }
  },
  created() {
    // this.getData('offices')
    // this.getData('positions')
    // this.getData('divisions')
    // this.getData('branches')
  }
}
