<template>
  <div>
    <div class="alert-title background__blue_document">
      <p class="title-secondary color__blue_main fs-20 fw__bold">{{$t('documents.detail.button.setting_alert')}}</p>
    </div>
    <div class="page-main-content mt-0" @keyup.enter="submit()">
      <div class="columns d-flex m__bottom--12">
        <div class="column">
          <div class="field">
              <div class="m__bottom--6">
                <label class="input-title color__blue_main fs-14 fw__bold">{{$t('setting_alert.placeholder.input_field.document_name')}}</label>
              </div>
              <div class="input-alert">
                <span class="fs-14 color__blue_main">{{ documentDetail.name }}</span>
              </div>
          </div>
          <div class="field">
              <div class="m__bottom--6">
                <label
                    class="input-title color__blue_main fs-14 fw__bold">{{$t('setting_alert.placeholder.input_field.create_at')}}</label>
              </div>
              <div class="input-alert">
                <span class="fs-14 color__blue_main">{{ momentFormat(documentDetail.created_at) }}</span>
              </div>
          </div>
        </div>

        <div class="column">
          <div class="field">
              <div class="m__bottom--6">
                <label
                    class="input-title color__blue_main fs-14 fw__bold">{{$t('setting_alert.placeholder.input_field.updated_at')}}</label>
              </div>
              <div class="input-alert">
                <span class="fs-14 color__blue_main">{{ momentFormat(documentDetail.updated_at) }}</span>
              </div>
          </div>
        </div>
      </div>
      <div class="columns d-flex m__bottom--12" v-if="settingAlert.send_type * 1 === 2">
        <div class="column p__top--12 is-full-mobile">
          <div class="field">
            <template v-if="settingAlert.send_type * 1 === 2">
                <div class="m__bottom--6">
                  <span class="input-title color__blue_main fs-14 fw__bold">
                    {{$t('setting_alert.placeholder.input_field.mail_templates')}}
                    <i :data-tooltip="$t('tooltip.required')" class="required-note">*</i>
                  </span>
                </div>
                <div class="">
                  <dropdown-search :list="mailTemplates"
                                   :is-invalid="isSubmitted && settingAlert.send_type * 1 === 2 && !settingAlert.mail_template"
                                   :placeholder="$t('setting_alert.placeholder.mail_templates')"
                                   :selected="settingAlert.mail_template"
                                   @change="selectMailTemplate($event)">
                  </dropdown-search>
                  <span class="error pt-1"
                        v-if="isSubmitted && settingAlert.send_type * 1 === 2 && !settingAlert.mail_template">
                        {{ $t('setting_alert.messages.validation.mail_template_required') }}
                      </span>
                </div>
            </template>
          </div>
        </div>
        <div class="column">

        </div>

      </div>
      <div class="columns">
        <div class="column p__top--12">
        <span
            class="input-title fs-14 color__blue_main fw__bold">
          {{$t('setting_alert.placeholder.input_field.destination')}}
          <i :data-tooltip="$t('tooltip.required')" class="required-note">*</i>
        </span>
        </div>
      </div>
      <div class="columns d-flex m__bottom--12">
        <div class="column p__top--6">
          <div class="m__bottom--18">
            <multiselect :list-data="officesUniq"
                         :selected="settingAlert.to"
                         :string-emit="true"
                         string-display="email"
                         string-compare="email"
                         label="To:"
                         :placeholder="$t('setting_alert.placeholder.mail_to')"
                         :is-invalid="!vuelidate.settingAlert.to.required && vuelidate.$dirty"
                         @change="getMail($event, 'to')">
            </multiselect>
            <span class="error pt-1" v-if="!vuelidate.settingAlert.to.required && vuelidate.$dirty">
                  {{ $t('setting_alert.messages.validation.mail_to_required') }}
                </span>
          </div>
          <div class="">
            <multiselect :list-data="createdBCC"
                         :selected="settingAlert.bcc"
                         :string-emit="true"
                         string-display="email"
                         :droppable="false"
                         :create-new="true"
                         :max-length="255"
                         msg-max-length="setting_alert.messages.validation.mail_bcc_maxlength"
                         msg-invalid-email="setting_alert.messages.validation.mail_bcc_invalid"
                         msg-duplicate="setting_alert.messages.validation.mail_bcc_duplicate"
                         :is-email="true"
                         string-compare="email"
                         label="BCC:"
                         :placeholder="$t('setting_alert.placeholder.mail_bcc')"
                         @create-item="createEmailBCC($event)"
                         @change="getMail($event, 'bcc')">
            </multiselect>
          </div>
        </div>
        <div class="column p__top--6">
          <div class="">
            <multiselect :list-data="officesUniq"
                         :selected="settingAlert.cc"
                         :string-emit="true"
                         string-display="email"
                         string-compare="email"
                         :string-prefix="emailSupport"
                         label="CC:"
                         :placeholder="$t('setting_alert.placeholder.mail_cc')"
                         @change="getMail($event, 'cc')">
            </multiselect>
          </div>
        </div>
      </div>
      <div class="columns d-flex m__bottom--12">
        <div class="column">
          <div class="field">
              <div class="m__bottom--6">
                <span
                    class="fw__bold input-title color__blue_main fs-14">
                  {{$t('setting_alert.placeholder.input_field.type_alert')}}
                  <i :data-tooltip="$t('tooltip.required')" class="required-note">*</i>
                </span>
              </div>
              <div class="">
                <div class="control d-flex">
                  <label class="radio d-flex mr-5 align-self-center align-items-center">
                    <input type="radio"
                           class="vertical-middle"
                           value="1"
                           name="send-mail-type"
                           v-model="settingAlert.send_type">
                    <span class="fs-14 color__blue_main fw__bold">自動</span>
                  </label>
                  <label class="radio d-flex align-self-center align-items-center">
                    <input type="radio"
                           class="vertical-middle"
                           value="2"
                           name="send-mail-type"
                           v-model="settingAlert.send_type">
                    <span class="fs-14 color__blue_main fw__bold">手動</span>
                  </label>
                </div>
              </div>
          </div>
        </div>
      </div>
      <div class="columns d-flex m__bottom--12" v-if="settingAlert.send_type * 1 === 2">
        <div class="column">
          <div class="field">
            <div class="col-sub-label p__bottom--6">
                <span class="field-label color__blue_main fs-14 fw__bold has-text-left">送信日付<i :data-tooltip="$t('tooltip.required')"
                                                                           class="required-note">*</i></span>
            </div>
            <div class="">
              <date-picker v-model="settingAlert.send_date" format="YYYY-MM-DD" type="date" lang="ja"
                           :class="{'is-error': isSubmitted && settingAlert.send_type * 1 === 2 && !settingAlert.send_date}"
                           class="w--100 ">
                <template slot="icon-calendar">
                  <img class="img-calendar" src="../../assets/svgs/ic_calendar.svg">
                </template>
              </date-picker>
              <span class="error pt-1"
                    v-if="isSubmitted && settingAlert.send_type * 1 === 2 && !settingAlert.send_date">
                  {{ $t('setting_alert.messages.validation.send_date_required') }}
                </span>
            </div>
          </div>
        </div>
        <div class="column">
        </div>
      </div>
      <div class="columns d-flex m__bottom--12" v-if="settingAlert.send_type * 1 === 2">
        <div class="column">
          <div class="field">
            <div class="d-flex is-align-items-flex-start">
              <div class="align-self-center col-sub-label align-self-start m__right--20">
                <span class="field-label color__blue_main fs-14 fw__bold">繰り返し</span>
              </div>
              <div class="col-sub-menu align-self-start">
                <div class="field">
                  <input id="sendInterval" type="checkbox" name="sendInterval"
                         class="switch is-rounded is-pink font-14" v-model="settingAlert.send_interval">
                  <label for="sendInterval"></label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="columns d-flex m__bottom--12" v-if="settingAlert.send_interval">
        <div class="column">
          <div
              class="align-self-start">
            <input type="number"
                   class="input input-alert-date color__blue_main fw__bold"
                   min="1"
                   :class="{'is-error': isSubmitted && settingAlert.send_interval && (!settingAlert.send_value || settingAlert.send_value < 1 || settingAlert.send_value > 100)}"
                   v-model="settingAlert.send_value">
            <template v-if="isSubmitted">
                      <span class="error pt-1 has-word-break"
                            v-if="settingAlert.send_interval && !settingAlert.send_value">
                        {{ $t('setting_alert.messages.validation.send_value_required') }}
                      </span>
              <span class="error pt-1 has-word-break"
                    v-else-if="settingAlert.send_interval && (settingAlert.send_value < 1 || settingAlert.send_value > 100)">
                        {{ $t('setting_alert.messages.validation.send_value_invalid') }}
                      </span>
            </template>
          </div>
        </div>
        <div class="column">
          <div
              class="align-self-start">
            <div class="dropdown w--100 is-right"
                 id="dropdown-send-unit"
                 v-click-out="()=>closeDropdown('dropdown-send-unit')">
              <div class="dropdown-trigger w--100">
                <button class="button w--100 is-justify-content-flex-start fs-14 input-alert-date color__blue_main"
                        aria-haspopup="true"
                        :class="{'is-error': isSubmitted && settingAlert.send_interval && !settingAlert.send_unit}"
                        @click="toggleDropdown('dropdown-send-unit')"
                >
                  {{ sendUnitName || '' }}
                  <span class="icon is-small"
                        :class="{'color-error': isSubmitted && settingAlert.send_interval && !settingAlert.send_unit}">
                                <img width="15px" src="@/assets/svgs/ic_drop_2.svg"/>
                              </span>
                </button>
              </div>
              <div class="dropdown-menu w--100" role="menu">
                <div class="dropdown-content">
                  <a @click="getSendUnit(e)"
                     v-for="(e, i) in sendUnits"
                     :key="'doc-type-' + i"
                     :class="{'is-active': e.id === settingAlert.send_unit}"
                     class="dropdown-item color__blue_main fs-14">
                    {{ e.name }}
                  </a>
                </div>
              </div>
            </div>
            <span class="error pt-1 has-word-break"
                  v-if="isSubmitted && settingAlert.send_interval && !settingAlert.send_unit">
                          {{ $t('setting_alert.messages.validation.send_unit_required') }}
                        </span>
          </div>
        </div>
      </div>
      <div class="columns">
        <div class="column is-full">
          <div class="field is-grouped is-grouped-centered mt-6">
            <div class="control">
              <button class="button p-0 btn-save background__blue_main color__white" @click="submit()">保存</button>
            </div>
            <div class="control">
              <button class="button btn-back is-borderless background__white_pink" @click="cancel()">キャンセル
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import {ACTION_SET_ACTIVE_SIDEBAR} from "@/stores/common/actions";
  import {DocumentService} from "@/services";
  import {momentFormat} from "@/filters";
  // import moment from "moment";
  import {required} from "vuelidate/lib/validators";
  import findIndex from 'lodash/findIndex'
  import uniqBy from 'lodash/uniqBy'
  import filter from 'lodash/filter'
  import DatePicker from 'vue2-datepicker'
  import 'vue2-datepicker/locale/ja'
  import User from '@/mixins/user'
  import {emailSupport} from "@/helpers/constant";

  export default {
    name: "SettingAlertMail",
    components: {DatePicker},
    mixins: [User],
    data() {
      return {
        mailTemplates: [],
        documentDetail: {},
        mailTemplate: {},
        emailSupport: emailSupport,
        settingAlert: {
          mail_template: null,
          to: '',
          cc: emailSupport,
          bcc: '',
          send_type: 1,
          send_interval: false,
          send_date: '',
          send_value: null,
          send_unit: null,
        },
        createdBCC: [],
        sendUnits: [
          {id: 1, name: '週'},
          {id: 2, name: '月'},
          {id: 3, name: '年'},
        ],
        sendUnitName: '',
        users: [],
        isSubmitted: false,
        officesUniq: [],
      }
    },
    validations: {
      settingAlert: {
        // mail_template: {
        //   required,
        // },
        to: {
          required,
        }
      },
    },
    computed: {
      docId() {
        return this.$route.params.id
      }
    },
    watch: {
      'settingAlert.send_type': function (val) {
        if (val * 1 === 2) {
          this.$nextTick(() => {
            if (!this.documentDetail.mail_document) {
              this.settingAlert.send_date = ''
              this.settingAlert.send_interval = false
              this.settingAlert.mail_template = ''
              this.settingAlert.send_value = null
              this.settingAlert.send_unit = 1
              this.sendUnitName = findIndex(this.sendUnits, e => e.id === this.settingAlert.send_unit) >= 0 && this.sendUnits[findIndex(this.sendUnits, e => e.id === this.settingAlert.send_unit)].name
              this.isSubmitted = false
              this.checkValidate()
            }
          })
        }
      },
      'settingAlert.send_interval': function (newVal, oldVal) {
        if (newVal !== oldVal && !this.documentDetail.mail_document) {
          this.settingAlert.send_value = null
          this.settingAlert.send_unit = 1
          this.sendUnitName = findIndex(this.sendUnits, e => e.id === this.settingAlert.send_unit) >= 0 && this.sendUnits[findIndex(this.sendUnits, e => e.id === this.settingAlert.send_unit)].name
        }
      },
      'settingAlert.send_date': function (val) {
        if (val) {
          this.$el.querySelector('.mx-input-wrapper input') && this.$el.querySelector('.mx-input-wrapper input').classList.remove('is-error')
        }
      },
      'settingAlert.mail_template': function (val) {
        if (val) {
          this.$el.querySelector('.mx-input-wrapper input') && this.$el.querySelector('.mx-input-wrapper input').classList.remove('is-error')
        }
      },
      offices(val) {
        if (val && val.length) {
          this.officesUniq = uniqBy([...val], 'email');
        }
      },
    },
    methods: {
      dateMin(date) {
        let d = new Date()
        d.setDate(d.getDate() - 1)
        return date < d
      },
      async getDocumentDetail() {
        await DocumentService.detail(this.docId)
          .then((res) => {
            if (res && res.data) {
              this.documentDetail = res.data
              if (this.documentDetail.mail_document) {
                this.settingAlert.mail_template = this.documentDetail.mail_document.mail_template_id
                this.mailTemplate.name = findIndex(this.mailTemplates, e => e.id === this.settingAlert.mail_template) >= 0 && this.mailTemplates[findIndex(this.mailTemplates, e => e.id === this.settingAlert.mail_template)].name
                this.settingAlert.to = this.documentDetail.mail_document.to ? this.documentDetail.mail_document.to : this.documentDetail.office.email
                this.settingAlert.cc = this.documentDetail.mail_document.cc ? this.documentDetail.mail_document.cc : emailSupport
                this.settingAlert.bcc = this.documentDetail.mail_document.bcc
                if (this.settingAlert.bcc) {
                  let data = [...this.settingAlert.bcc.split(',').map(e => e.trim())]
                  data.map((e) => {
                    this.createdBCC = [...this.createdBCC, ...[{id: e, email: e}]]
                  })
                }
                this.settingAlert.send_type = this.documentDetail.mail_document.type
                this.settingAlert.send_interval = this.documentDetail.mail_document.is_repeated
                this.settingAlert.send_date = this.documentDetail.mail_document.send_at ? new Date(this.documentDetail.mail_document.send_at.replace(' ', 'T')) : new Date()
                this.settingAlert.send_value = this.documentDetail.mail_document.repeat_value
                this.settingAlert.send_unit = this.documentDetail.mail_document.repeat_unit || 1
                this.sendUnitName = findIndex(this.sendUnits, e => e.id === this.settingAlert.send_unit) >= 0 && this.sendUnits[findIndex(this.sendUnits, e => e.id === this.settingAlert.send_unit)].name
              } else {
                this.settingAlert.to = this.documentDetail.office.email
                this.settingAlert.cc = emailSupport
                this.settingAlert.send_unit = 1
                this.sendUnitName = findIndex(this.sendUnits, e => e.id === this.settingAlert.send_unit) >= 0 && this.sendUnits[findIndex(this.sendUnits, e => e.id === this.settingAlert.send_unit)].name
              }
              this.getMailTemplate()
            }
          })
          .catch((err) => {
            console.log(err)
          })
      },
      getMailTemplate() {
        DocumentService.mailTemplate()
          .then((res) => {
            this.mailTemplates = res.data.mail_templates
            if (this.documentDetail.mail_document) {
              this.mailTemplate.name = findIndex(this.mailTemplates, e => e.id === this.settingAlert.mail_template) >= 0 && this.mailTemplates[findIndex(this.mailTemplates, e => e.id === this.settingAlert.mail_template)].name
            }
          })
          .catch((err) => {
            console.log(err)
          })
      },
      getSendUnit(e) {
        this.settingAlert.send_unit = e.id
        this.sendUnitName = e.name
        this.toggleDropdown('dropdown-send-unit')
      },
      createEmailBCC(e) {
        this.createdBCC = [...this.createdBCC, ...[{id: e, email: e}]]
        this.createdBCC =  filter(this.createdBCC, e => e.email !== '')
        this.settingAlert.bcc = [...this.createdBCC].map(e => e.email).join(',')
      },
      getMail(e, type) {
        this.settingAlert[type] = e
        if (type === 'bcc') {
          this.createdBCC = []
          e.split(',').map((el) => {
            this.createdBCC = [...this.createdBCC, ...[{id: el, email: el}]]
          })
        }
        // if (type === 'bcc') {
        //   let data = [...e.split(',').map(e => e.trim())]
        //   this.createdBCC = []
        //   data.map((el) => {
        //     this.createdBCC = [...this.this.createdBCC, ...[{id: el, email: el}]]
        //   })
        // }
      },
      checkValidate() {
        let flag = true
        if (this.isSubmitted) {
          if (this.settingAlert.send_type * 1 === 2) {
            if (!this.settingAlert.send_date) {
              flag = false
              this.$el.querySelector('.mx-input-wrapper input').classList.add('is-error')
            } else {
              this.$el.querySelector('.mx-input-wrapper input').classList.remove('is-error')
            }
            if (this.settingAlert.send_interval) {
              if (!this.isRequired(this.settingAlert.send_value) || !this.isRequired(this.settingAlert.send_unit)) {
                flag = false
              }
              if (this.settingAlert.send_value < 1 || this.settingAlert.send_value > 100) {
                flag = false
              }
            }
          }
        }

        return flag
      },
      submit() {
        this.vuelidate.$touch()
        this.isSubmitted = true
        let flag = this.checkValidate()
        if (!this.vuelidate.$invalid && flag) {
          let data = Object.assign(this.settingAlert)
          if (this.settingAlert.send_type * 1 === 1) {
            delete data.sendDate
            delete data.sendInterval
            delete data.sendUnit
            delete data.sendValue
          } else {
            if (!data.sendInterval) {
              delete data.sendUnit
              delete data.sendValue
            }
          }
          DocumentService.settingAlert(this.docId, data).then(() => {
            this.$toast.success(this.$t('setting_alert.messages.setting_alert_mail_success'))
            this.$router.push({name: 'BtoB', params: {id: this.docId}}, () => {
            })
          }).catch((err) => {
            console.log(err)
            this.$toast.error(this.$t('setting_alert.messages.setting_alert_mail_failed'))
          })
        }
      },
      cancel() {
        this.$router.push({name: 'BtoB', params: {id: this.docId}}, () => {
        })
      },
      momentFormat(date, format = 'YYYY-MM-DD') {
        return momentFormat(date, format)
      },
      selectMailTemplate(e) {
        this.mailTemplate = e
        this.settingAlert.mail_template = e.id
      },
      closeDropdown(id) {
        this.$nextTick(() => {
          this.$el.querySelector(`#${id}`).classList.remove('is-active')
        })
      },
      toggleDropdown(id) {
        this.$nextTick(() => {
          this.$el.querySelector(`#${id}`).classList.toggle('is-active')
          this.$refs['dropdown-keyword'] && this.$refs['dropdown-keyword'].focus()
        })
      },
    },
    created() {
      this.getData('offices')
      this.$store.dispatch(ACTION_SET_ACTIVE_SIDEBAR, 'folders')
      this.getDocumentDetail()
    }
  }
</script>

<style lang="scss" scoped>
  ::v-deep {
    .mx-input {
      color: $blue_main;
      font-size: 12px;
      font-weight: bold;
      &:hover, :focus {
        border: 1px solid #006FC3 !important;
        box-shadow: none !important;
      }
    }
  }
  .alert-title {
    padding: 10px 60px;
    border-radius: 10px 10px 0 0;
  }
  .img-calendar {
    width: 20px;
    height: 20px;
  }
  .page-main-content {
    padding: 20px 60px 60px 60px;
    border-radius: 0 0 10px 10px;
    .columns {
      margin: 0px;
      .column {
        padding-left: 0px;
        padding-bottom: 0px;
      }
    }
  }
  .input-alert {
    padding: 8px 20px;
    border-radius: 5px;
    background-color: $bg_input;
  }
  .input-alert-date {
    border-radius: 5px !important;
    padding-left: 20px;
    font-size: 14px !important;
    height: 40px;
    &:hover {
      border: 1px solid #006FC3;
      box-shadow: none;
    }
  }
  button {
    &.btn-save {
      border-radius: 18px !important;
      border: none !important;
      padding: 0px !important;
      height: 35px !important;
      width: 140px !important;
      font-size: 16px !important;
    }
    &.btn-back {
      border-radius: 18px !important;
      border: none !important;
      padding: 0px !important;
      height: 35px !important;
      width: 140px !important;
      font-size: 16px !important;
      color: $btn_back !important;
    }
  }
  .col-sub-label {
    max-width: 130px !important;
  }
  input[type='radio'] {
    width: 21px !important;
    height: 21px !important;
    &:before {
      width: 15px !important;
      height: 15px !important;
    }
  }
  .dropdown {
    width: 100% !important;
  }
  .dropdown-trigger {
    width: 100% !important;
  }
  .dropdown-menu {
    min-width: 100% !important;
  }
  .dropdown-content {
    width: 100% !important;
  }
  .m__bottom--200 {
    margin-bottom: 200px !important;
  }
  .switch[type=checkbox].is-rounded+label::after,.switch[type=checkbox].is-rounded+label:after {
    border-radius: 50%;
    background: #006FC3;
  }
  .switch[type=checkbox]+label::after,.switch[type=checkbox]+label:after {

  }
  .switch[type=checkbox]+label::before,.switch[type=checkbox]+label:before {
    width: 60px !important;
    height: 22px !important;
    background-color: $white_pink;
  }
  .switch[type=checkbox]+label::after, .switch[type=checkbox]+label:after {
    width: 18px;
    height: 18px;
    top: 2px;
  }
  .switch[type=checkbox]:checked+label::after {
    left: 38px;
  }
  @media screen and (max-width: 768px) {
    .page-list-data {
      .column:first-child,
      .column:nth-child(2) {
        padding: 10px !important;
      }
    }
  }

    @media screen and (max-width: 1600px) {
      .font-14, input {
        font-size: 0.9rem !important
      }
    }

  @media screen and (max-width: 1600px) {
    .font-14, input {
      font-size: 0.9rem !important
    }
  }
  @media screen and (min-width: 769px){
    .column.is-one-fifth, .column.is-one-fifth-tablet {
      min-width: 25%;
    }
  }
  @media screen and (max-width: 1024px){
    .alert-title {
      padding: 10px 20px;
    }
    .page-main-content {
      padding: 20px 20px 60px 20px;
    }
  }

</style>

<style>
  .switch[type=checkbox]:focus + label::after,
  .switch[type=checkbox]:focus + label::before,
  .switch[type=checkbox]:focus + label:after,
  .switch[type=checkbox]:focus + label:before {
    outline: none !important;
  }
</style>
